
































































































































// @ is an alias to /src
import { defineComponent, PropType, reactive } from '@vue/composition-api';

import useUtils from '@/composition/utils';
import { IInput } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IInput>,
      required: true,
    },
    value: [Number, String, Object, File],
  },

  setup(props, { emit }) {
    const { isUrl } = useUtils();
    const state = reactive({
      fileName: '',
      prevValue: '',
    });

    const onFocus = (e: Event): void => {
      if (props.config.onFocus) {
        props.config.onFocus(e);
      }
    };

    const updateFile = ($input: HTMLInputElement): void => {
      if ($input.files && $input.files[0]) {
        // Clear previous file
        emit('input', $input.files[0]);

        state.fileName = $input.files[0].name;

        if (props.config.onFileSelect) {
          props.config.onFileSelect($input.files[0]);
        } else {
          props.config.file = undefined;
        }
        $input.value = '';
        props.config.message = '';
      }
    };

    const onBlur = (): void => {
      if (props.config.onBlur) {
        props.config.onBlur();
      }
    };

    const onDelete = (): void => {
      if (props.config.onDelete) {
        props.config.onDelete();
      }
      state.fileName = '';
      emit('input', '');
    };

    const updateValue = (value: string, $event: Event): void => {
      if (!props.config.maxValue || props.config.maxValue > parseInt(value, 10)) {
        if (props.config.type === 'number') {
          state.prevValue = value;
          emit('input', parseInt(value, 10));
        } else {
          state.prevValue = value;
          emit('input', value);
        }
        if (props.config.onInput) {
          props.config.onInput(value);
        }
      } else if (value && props.config.maxValue && $event.target) {
        // eslint-disable-next-line no-param-reassign
        (<HTMLInputElement>$event.target).value = state.prevValue;
      }
    };

    return {
      onBlur,
      updateValue,
      updateFile,
      onFocus,
      onDelete,
      isUrl,
      state,
    };
  },
});
